<template>
  <TaskListParentSelectPopover
    v-if="task.level !== TaskLevel.MODULE"
    ref="parentRef"
    :hide-unlink="task.level === TaskLevel.SUBTASK"
    :selected-task-id="value?.id"
    :board-id="boardId"
    :section-id="task.sectionId"
    :disabled="disabled"
    :parent-level="acceptLevel"
    :task-id="taskId"
    :data-trigger="`task-parent-${task.id}`"
    :trigger-class="triggerClass"
    button-class="px-2 py-1.5 items-center flex"
    @change="handleChange"
    @update:open="handleUpdateOpen"
  >
    <div data-test="task-parent-item">
      <div v-if="value" class="flex items-center gap-1.5">
        <TaskTypeIcon
          size="xs"
          background-class="!bg-transparent"
          icon-class="!bg-gray-900 !size-4"
          :icon="value.type?.icon"
        />
        <Tooltip :text="value.name">
          <template #default="{ getTextRef }">
            <span :ref="getTextRef" class="text-gray-700 line-clamp-1 break-all">{{ value.name }}</span>
          </template>
        </Tooltip>
      </div>
      <div v-else class="text-xs group-hover/cell:visible invisible" :class="{ '!cursor-not-allowed': disabled }">
        -
      </div>
    </div>
  </TaskListParentSelectPopover>
  <div v-else class="h-8 min-h-8 list-view-cell w-full relative" />
</template>

<script lang="ts" setup>
import type { TaskItem } from '#task/types'
import { TaskLevel } from '#task/constant'
import type { TaskFieldRef } from '#field/types'

defineOptions({
  inheritAttrs: false,
})

const props = defineProps({
  taskId: {
    type: String,
    required: true,
  },
  task: {
    type: Object as PropType<TaskItem>,
    required: true,
  },
  boardId: {
    type: String,
    required: true,
  },
  value: {
    type: [Object, null] as PropType<TaskItem | null>,
    required: true,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  dataTest: {
    type: String,
  },
  triggerClass: {
    type: Function as PropType<(active: boolean) => string[]>,
  },
})

const emit = defineEmits<{
  (e: 'update:open', value: boolean): void
}>()

const { updateTasks } = useUpdateTask()
const { setExpanded, isExpanded } = useListViewSharedState()
const parentRef = ref()
const open = ref(false)

const acceptLevel = computed(() => props.task.level === TaskLevel.TASK ? TaskLevel.MODULE : TaskLevel.TASK)

const { activeView } = useWorkspaceSharedState()
const { tasks } = useListViewTasksLoader(activeView.value)
const { getToUpdateTaskIds } = useUpdateTask()

const selectedTasks = computed(() => {
  const taskIds = getToUpdateTaskIds([props.task.id], true)
  return tasks.value.filter(task => taskIds.includes(task.id))
})

const updateTaskParent = async (parent: string | null | undefined) => {
  if (props.task.level === TaskLevel.SUBTASK) {
    const taskIds = selectedTasks.value.filter(task => task.level === TaskLevel.SUBTASK)?.map(task => task.id)
    updateTasks(taskIds, { parent: parent ?? null })
    return
  }

  const taskIds = selectedTasks.value.reduce((acc: TaskItem[], task: TaskItem) => {
    if (task.level !== TaskLevel.SUBTASK) {
      acc.push(task)
    }

    return acc
  }, [])

  updateTasks(taskIds.map(task => task.id), { parent: parent ?? null })
}

const handleUpdateOpen = (value: boolean) => {
  open.value = value
  emit('update:open', value)
}

const handleChange = (task: TaskItem | null) => {
  updateTaskParent(task?.id)
  if (task && !isExpanded(task.id)) {
    setExpanded(task.id, true)
  }
}

defineExpose<TaskFieldRef>({
  open: () => {
    parentRef.value?.open()
  },
  close: () => {
    parentRef.value?.close()
  },
  delete: () => {
    // Do nothing
    updateTaskParent(null)
  },
})
</script>

<style scoped lang="scss">
[data-is-focus="true"]>.list-view-cell {
  @apply hover:border-primary-500  !important;
}

.list-view-cell {
  @apply hover:!border-inherit;
}
</style>
